import sweatshirtBlueImg from '../assets/items/sweatshirts/sweatshirtBlue.png'
import sweatshirtBlueImg2 from '../assets/items/sweatshirts/sweatshirtBlue2.png'
import sweatshirtRedImg from '../assets/items/sweatshirts/sweatshirtRed.png'
import sweatshirtRedImg2 from '../assets/items/sweatshirts/sweatshirtRed2.png'
import sweatShirtCharcoalImg from '../assets/items/sweatshirts/sweatshirtCharcoal.png'
import sweatShirtCharcoalImg2 from '../assets/items/sweatshirts/sweatshirtCharcoal2.png'
import sweatShirtElectricImg from '../assets/items/sweatshirts/sweatshirtElectric.png'
import sweatShirtElectricImg2 from '../assets/items/sweatshirts/sweatshirtElectric2.png'
import sweatShirtTangerineImg from '../assets/items/sweatshirts/sweatshirtTangerine.png'
import sweatShirtTangerineImg2 from '../assets/items/sweatshirts/sweatshirtTangerine2.png'
import sweatShirtYellowImg from '../assets/items/sweatshirts/sweatshirtYellow.png'
import sweatShirtYellowImg2 from '../assets/items/sweatshirts/sweatshirtYellow2.png'

import pantsBlueImg from '../assets/items/pants/pantsBlue.png'
import pantsBlue2Img from '../assets/items/pants/pantsBlue2.png'
import pantsCreamImg from '../assets/items/pants/pantsCream.png'
import pantsCream2Img from '../assets/items/pants/pantsCream2.png'
import pantsGreyImg from '../assets/items/pants/pantsGrey.png'
import pantsGrey2Img from '../assets/items/pants/pantsGrey2.png'

import tshirtOrangeImg from '../assets/items/tshirts/tshirtOrange.png'
import tshirtOrangeImg2 from '../assets/items/tshirts/tshirtOrange2.png'
import tshirtBlueImg from '../assets/items/tshirts/tshirtBlue.png'
import tshirtBlueImg2 from '../assets/items/tshirts/tshirtBlue2.png'
import tshirtBrownImg from '../assets/items/tshirts/tshirtBrown.png'
import tshirtBrownImg2 from '../assets/items/tshirts/tshirtBrown2.png'

import hoodieBrownImg from '../assets/items/Hoodies/hoodieBrown.png'
import hoodieBrownImg2 from '../assets/items/Hoodies/hoodieBrown2.png'
import hoodieOrangeImg from '../assets/items/Hoodies/orangeHoodie.png'
import hoodieOrangeImg2 from '../assets/items/Hoodies/hoodieOrange2.png'
import hoodiePurpleImg from '../assets/items/Hoodies/hoodiePurple.png'
import hoodiePurpleImg2 from '../assets/items/Hoodies/hoodiePurple2.png'
import hoodieGreenImg from '../assets/items/Hoodies/hoodieGreen.png'
import hoodieGreenImg2 from '../assets/items/Hoodies/hoodieGreen2.png'
import hoodieGreyImg from '../assets/items/Hoodies/hoodieGrey.png'
import hoodieGreyImg2 from '../assets/items/Hoodies/hoodieGrey2.png'

function Sweatshirt(color, colorName, image, image2, id) {
    this.id = id
    this.itemPrice = 55.00
    this.color = color
    this.colorName = colorName
    this.image = image
    this.image2 = image2
    this.itemName = `Organic Sweatshirt - ${colorName}`
    this.destination = '/Sweatshirt'
    this.des = "Our Men's Oversized Crewneck Jumper, a perfect blend of comfort and style for those who appreciate laid-back sophistication. This high-quality jumper is made from premium organic cotton, offering a superior level of softness and breathability."
}

const sweatshirtBlue = new Sweatshirt('#38506D', 'Blue', sweatshirtBlueImg, sweatshirtBlueImg2, 1)
const sweatshirtRed = new Sweatshirt('#380F1C', 'Maroon', sweatshirtRedImg, sweatshirtRedImg2, 2)
const sweashirtCharcoal = new Sweatshirt('#292929', 'Charcoal', sweatShirtCharcoalImg, sweatShirtCharcoalImg2, 3)
const sweashirtElectric = new Sweatshirt('#0079CD', 'Electric Blue', sweatShirtElectricImg, sweatShirtElectricImg2, 4)
const sweashirtTangerine = new Sweatshirt('#F04956', 'Tangerine', sweatShirtTangerineImg, sweatShirtTangerineImg2, 5)
const sweashirtYellow = new Sweatshirt('#F9E9B3', 'Yellow', sweatShirtYellowImg, sweatShirtYellowImg2, 6)

export const sweatshirts = [sweatshirtBlue, sweatshirtRed, sweashirtCharcoal, sweashirtElectric, sweashirtTangerine, sweashirtYellow]

function Tshirt(color, colorName, image, image2, id) {
    this.itemPrice = 45.00
    this.color = color
    this.image = image
    this.image2 = image2
    this.itemName = `Oversized T-shirt - ${colorName}`
    this.destination = '/Tshirt'
    this.colorName = colorName
    this.id = id
    this.des = "Introducing our Men's Oversized T-Shirt, designed with a versatile oversized fit for premium comfort. Crafted from high quality, breathable 100% organic cotton."
}

const tshirtOrange = new Tshirt('#C95F4D', 'Orange',tshirtOrangeImg, tshirtOrangeImg2, 1)
const tshirtBlue = new Tshirt('#38506D', 'Blue', tshirtBlueImg, tshirtBlueImg2, 2)
const tshirtBrown = new Tshirt('#756354', 'Brown', tshirtBrownImg, tshirtBrownImg2, 3)

export const tShirts = [tshirtBlue, tshirtOrange, tshirtBrown]

function Hoodie(color, colorName, image, image2, id) {
    this.itemPrice = 70.00
    this.color = color
    this.image = image
    this.image2 = image2
    this.itemName = `Oversized Hoodie - ${colorName}`
    this.destination = '/Hoodie'
    this.id = id
    this.colorName = colorName
    this.des = "Introducing our Men's Oversized Organic Hoodie - where comfort meets contemporary style for an effortlessly cool look. Crafted from premium organic cotton."
}

const hoodieBrown = new Hoodie('#756354', 'Brown', hoodieBrownImg, hoodieBrownImg2, 1)
const hoodieOrange = new Hoodie('#FFA84F', 'Orange', hoodieOrangeImg, hoodieOrangeImg2, 2)
const hoodiePurple = new Hoodie('#D6CEE5', 'Purple', hoodiePurpleImg, hoodiePurpleImg2, 3)
const hoodieGreen = new Hoodie('#007547', 'Green', hoodieGreenImg, hoodieGreenImg2, 4)
const hoodieGrey = new Hoodie('#DFDFDF', 'Grey', hoodieGreyImg, hoodieGreyImg2, 5)


export const hoodies = [hoodieBrown, hoodieOrange, hoodiePurple, hoodieGreen, hoodieGrey]

function Pants(color, colorName, image, image2, id) {
    this.itemPrice = 70.00
    this.color = color
    this.image = image
    this.image2 = image2
    this.itemName = `Organic Sweatpants - ${colorName}`
    this.destination = '/Sweatpant'
    this.id = id
    this.colorName = colorName
    this.des = "Introducing our Men's Organic Sweatpants crafted in our soft and lightweight organic cotton fabric, with a touch of 3% elastane adding extra room offering a relaxed fit."
}

const pantsGrey = new Pants('#7B7B7B', 'Grey', pantsGreyImg, pantsGrey2Img, 1)
const pantsBlue = new Pants('#30485A', 'Blue', pantsBlueImg, pantsBlue2Img, 2)
const pantsCream = new Pants('#D8CDBA', 'Cream', pantsCreamImg, pantsCream2Img, 3)

export const sweatpants = [pantsGrey, pantsBlue, pantsCream]

export const latestProducts = [
    sweatshirtBlue,
    pantsGrey,
    hoodieBrown,
    tshirtOrange
]

export const latestProductsTwo = [
    tshirtBlue,
    hoodieOrange,
    sweatshirtRed,
    pantsBlue
]

export const random = [hoodies, sweatpants, tShirts, sweatshirts]
