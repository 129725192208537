import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import '../styles/Cart.css'

export const Cart = ({tt, cartOpenFunc, finishFunc}) => {

  const [items, setItems] = useState(JSON.parse(localStorage.getItem('cart')))

  const [totalAmount, setTotalAmount] = useState(0)

  const [cartEmptyAnim, setCartEmptyAnim] = useState(false)

  function calculate() {

    let amount = 0

      for (let itemArray of items) {
        for (let item of itemArray) {
          amount += item.itemPrice
          setTotalAmount(amount)
          console.log('calc2')
        }
      }
    console.log('calc')
  }

  function cartEmpty() {
    if (items == 0) {
      console.log('empty')
    }
  }

  function recalculate() {
    let amount = 0

    if (items.length == 0) {
      console.log('yes')
      setTotalAmount(0)
      return


    } else {
      

      for (let itemArray of items) {
        for (let item of itemArray) {
          amount += item.itemPrice
          setTotalAmount(amount)
          console.log('calc2')
        }
      }
    }
  }

  useEffect(() => {

    calculate()
    console.log('llllll')

    
  }, [])

  function removeItem(item) {
    for (let itemArr of items) {
      for (let cartItem of itemArr)
        if (cartItem.size == item.size && cartItem.color == item.color) {
          let updatedCurrentCart = items.filter(arr => arr !== itemArr)
          itemArr.pop()
          let updatedCart = [...updatedCurrentCart, itemArr]
          localStorage.setItem('cart', JSON.stringify(updatedCart))
          setItems(updatedCart)
          break
        }
    }
    let newCart = items.filter(arr => arr.length != 0)
    localStorage.setItem('cart', JSON.stringify(newCart))
    setItems(newCart)
    tt()
    recalculate()
    if (newCart.length == 0) {
      cartOpenFunc()
    }
    cartEmpty()
    if (items == 0) {
      setCartEmptyAnim(true)
    }
  }

  
  return (
    <>
      <div className='spacer'></div>
      <div className='cart-container'>
        <section className='leftside'></section>
        <section className='cart-items-container'>
          

          {
          items.map((item, idx) => {
            const firstItem = item[0]; // Accessing the first item of the inner array
            if (firstItem) {
              return <CartItem data={firstItem} arrData={item} key={idx} removeFunc={removeItem} />;
            }
            
          })
          }


          
        </section>
        <section className='checkout-column'>
          <div className='checkout-container'>
            <div className={`checkout ${!cartEmptyAnim ? 'active' : ''}`}>
              <h3>{`Total amount - £${totalAmount}`}</h3>
              <Link to={'/Finish'}><button className='checkOut-button' onClick={() => finishFunc()}>CHECKOUT</button></Link>
            </div>
          </div>
        </section>
        <div className={`cart-empty-popUp ${cartEmptyAnim ? 'active' : ''}`}>
          <h1>Cart is empty</h1>
        </div>
      </div>
    </>
  )
}

function CartItem({data, arrData, removeFunc}) {
  
  return (
    <>
      <div className='cart-item-container'>
        <div className='cart-item-img-wrapper'>
          <img src={data.image} alt="" />
        </div>
        <div className='item-info-container'>
          <div className='name-remove-flex'>
            <h1 className='item-name'>{data.itemName}</h1>
            <button onClick={() => removeFunc(data)}>Remove item</button>
          </div>
          <h2 className='item-size'>{`Size - ${data.size}`}</h2>
          <h2 className='item-quantity'>{`Quantity - ${arrData.length}`}</h2>
          <h2 className='cart-item-price'>{`Price - £${data.itemPrice}`}</h2>
        </div>
      </div>
    </>
  )
}