import React from 'react'
import { Link } from 'react-router-dom'

import '../styles/Finish.css'

import gitLogo from '../assets/github-mark.png'

function Finish({finishFunc}) {
  return ( <>
      <div className='finish-flex'>
            <h1>Thank you for clicking through my project</h1>
            <div className='finish-links'>
            <a class="header-contact" href="mailto: roberts.am@outlook.com"><h2 className='finish-contact-me'>Contact me</h2></a>
                <a href="https://github.com/darkmodeaaron"><div className='finish-flex-gitimg'><img src={gitLogo} alt="" /></div></a>
            </div>
            <Link to={'/'}><h2 className='back-to-site' onClick={() => finishFunc()}>Back to site</h2></Link>
      </div>
  </>
  )
}

export default Finish

