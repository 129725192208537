import React, { useEffect, useState } from 'react'

// css
import '../styles/Hero.css'

// assets
import heroImg from '../assets/hero.png'

function Hero() {

  const [noGo, setNoGo] = useState(false)

  function noGoClicked() {
    setNoGo(true)
    setTimeout(function() {
      setNoGo(false)
    }, 500)
    
  }

  return (
    <div className='hero' style={{backgroundImage: `url(${heroImg})`}}>
        <h1>THE DESTINATION FOR YOUR STYLE JOURNEY</h1>
        <div className={`view-all-products ${noGo ? 'active' : ''}`} onClick={noGoClicked}>VIEW ALL PRODUCTS</div>
    </div>
  )
}

export default Hero