import React, { useEffect, useState} from 'react'
import { Link } from 'react-router-dom'

import { random } from '../data/items'


import '../styles/productPage/randomItems.css'

export const RandomItems = ({page, duoFunc}) => {

    const [items, setItems] = useState([]);
    const [blur, setBlur] = useState(JSON.parse(localStorage.getItem(page)))

       

    useEffect(() => {


        const updatedItems = [];

        for (const list of random) {

            let randomIndex = Math.floor(Math.random() * list.length);
            const item = list[randomIndex];
            
            if (item.itemName === blur.itemName) {
                const newItem = list[randomIndex + 1]
                updatedItems.push(newItem);
                console.log('+')

            } else {
                updatedItems.push(item)
            }
            
        }

        setItems(updatedItems);

    }, []);

  return (
    <section className='random-items-container'>
        <h1>You may also like...</h1>
        <div className='random-items'>
            {
                items.map((item, index) => {
                    return <LatestProductItem data={item} key={index} passDuoFunc={duoFunc}/>
                })
            }
        </div>
    </section>
  )
}

function LatestProductItem({data, passDuoFunc}) {

    const [itemImageState, setItemImageState] = useState(data.image)

    const onImageHover = () => {
        setItemImageState(data.image2)
    }
    const onImageOut = () => {
        setItemImageState(data.image)
    }

    function addItem(item) {
        
        localStorage.setItem(`${item.destination}`, JSON.stringify(item))
        passDuoFunc(item)
        
    }
    
    return (
        <div className='latest-product-item'>
            <Link to={data.destination}>
            <div className='item-img-wrapper'><img src={itemImageState} alt="" onClick={() => addItem(data)}/></div></Link>
            <div className='item-title-price-wrapper'>
                <a className='item-title' href="">{data.itemName}</a>
                <h3 className='item-price'>{`£${data.itemPrice}`}</h3>
            </div>
        </div>
    )
}