import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom"
import { useState } from "react";
import { useLocation } from "react-router-dom";

import Head from "./components/Head";
import MobileHeader from "./components/MobileHeader";

// Pages
import Home from "./pages/Home";
import { Sweatshirt } from "./pages/Sweatshirt";
import { Tshirt } from "./pages/Tshirt";
import { Hoodie } from "./pages/Hoodie";
import { Sweatpants } from "./pages/Sweatpants";
import { Cart } from "./pages/Cart";
import Finish from "./pages/Finish";

import { random } from "./data/items";

function App() {

  const oldCart = localStorage.getItem('cart')

  const [cartOpen, setCartOpen] = useState(false)
  const [cartEmpty, setCartEmpty] = useState(false)

  function setCartOpenState() {
    if (!cartOpen) {
      setCartOpen(true)
    } else {
      setCartOpen(false)
    }
  }



  if (!oldCart) {
    const defaultCart = JSON.stringify([])
      localStorage.setItem('cart', defaultCart)
  }

  function cartCount() {
    if (cartLength > 0) {
      setCartOpen(true)
    }
  }

  const location = useLocation()

  const [headerColor, setHeaderColour] = useState(true)

  useEffect(() => {

    if (location.pathname != '/') {
       setHeaderColour(false)
    }

    if (location.pathname == '/') {
      setHeaderColour(true)
   }

  hideheaderLoad()     

  cartCount()

  console.log(cartLength)
  console.log(cartOpen)

  }, [])


  const [cartLength2, setCartLength2] = useState(JSON.parse(localStorage.getItem('cart')))


  const [cartLength, setCartLength] = useState(JSON.parse(localStorage.getItem('cart')).length)
  const [cartVis, setCartVis] = useState(false)

  function plusOne() {
    setCartLength(cartLength + 1)
  }

  function minusOne() {
    setCartLength(cartLength - 1)
  }

  const handleProtectedRoute = (e) => {
    if (!cartOpen) {
      e.preventDefault();
      setCartEmpty(true)
      setTimeout(function() {
        setCartEmpty(false)
      }, 1500)
      console.log('nooooooo')
    }
    else {
      return
    }
  };

  const [homeScrollState, setHomeScrollState] = useState(false)

  function toggleScroll() {
    if (homeScrollState) {
      setHomeScrollState(false)
      
    } else {
      setHomeScrollState(true)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      setHomeScrollState(false)
    })
  }, [])

  const [headerVis, SetHeaderVis] = useState(true)

  function hideHeader() {
    if (location.pathname != '/Finish') {
      SetHeaderVis(false)
    } else {
      SetHeaderVis(true)
    }
  }

  function hideheaderLoad() {
    if (location.pathname != '/Finish') {
      SetHeaderVis(true)
    } else {
      SetHeaderVis(false)
    }
  }
  
  return (
    <>
      <Head hCState={headerColor} cc={cartLength} cc2={cartLength2} cartShutFunc={handleProtectedRoute} cartEmptyState={cartEmpty} hss={homeScrollState} finishState={headerVis}/>
      <MobileHeader toggleScrollFunc={toggleScroll} cc={cartLength} cartShutFunc={handleProtectedRoute} cartEmptyState={cartEmpty} finishState={headerVis}/>
      
      <Routes>
        <Route path="/" element={<Home scrollState={homeScrollState}/>}></Route>
        <Route path="/Cart" element={<Cart  tt={minusOne} cartOpenFunc={setCartOpenState} finishFunc={hideHeader}/>}></Route>
        <Route path="Sweatshirt" element={<Sweatshirt cc={plusOne} cartOpenState={cartOpen} cartOpenFunc={setCartOpenState}/>}></Route>
        <Route path="Tshirt" element={<Tshirt cc={plusOne} cartOpenState={cartOpen} cartOpenFunc={setCartOpenState}/> } ></Route>
        <Route path="Hoodie" element={<Hoodie cc={plusOne} cartOpenState={cartOpen} cartOpenFunc={setCartOpenState}/>}></Route>
        <Route path="Sweatpant" element={<Sweatpants cc={plusOne} cartOpenState={cartOpen} cartOpenFunc={setCartOpenState}/>}></Route>
        <Route path="/Finish" element={<Finish finishFunc={hideHeader}/>}></Route>
      </Routes>
    </>
  );
}

export default App;
