import React from 'react'

import '../styles/Footer.css'

import twitterIcon from '../assets/icons/twitter.png'
import instagramIcon from '../assets/icons/instagram.png' 

const Footer = () => {

    

  function noGoClicked(e) {
    const target = e.target
    target.classList.add('active')
    setTimeout(function() {
      target.classList.remove('active');
    }, 500)
    
  }

  return (
    <footer>
        <div className='footer-flex'>
            <div className='store-locator-column'>
                <ul className='footer-column'>
                    <li className='footer-title' >STORE LOCATOR</li>
                    <li className='find-store-btn' onClick={noGoClicked} >FIND STORE</li>
                </ul>
            </div>
            <div className='help-and-info-column footer-column'>
                <ul className='footer-column'>
                    <li className='footer-title' >HELP AND INFORMATION</li>
                    <li className='column-sub' onClick={noGoClicked}>Help Center</li>
                    <li className='column-sub' onClick={noGoClicked}>Ordering & Payment</li>
                    <li className='column-sub' onClick={noGoClicked}>Shipping</li>
                    <li className='column-sub' onClick={noGoClicked}>Returns</li>
                    <li className='column-sub' onClick={noGoClicked}>Reviews</li>
                    <li className='column-sub' onClick={noGoClicked}>Contact Us</li>
                </ul>
            </div>
            <div className='company-column'>
                <ul className='footer-column'>
                    <li className='footer-title'>COMPANY</li>
                    <li className='column-sub' onClick={noGoClicked}>Careers</li>
                    <li className='column-sub' onClick={noGoClicked}>Accessibility</li>
                    <li className='column-sub' onClick={noGoClicked}>Cookie Preference</li>
                    <li className='column-sub' onClick={noGoClicked}>Terms & Conditions</li>
                    <li className='column-sub' onClick={noGoClicked}>Privacy Policy</li>
                </ul>
            </div>
            <div className='socials-column'>
                <div className='icon-wrapper' onClick={noGoClicked}>
                    <img src={twitterIcon} alt="" />
                </div>
                <div className='icon-wrapper' onClick={noGoClicked}>
                    <img src={instagramIcon} alt="" />
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer