import React from 'react'

// components
import Hero from '../components/Hero'
import HoverClicks from '../components/HoverClicks'
import LatsestProducts from '../components/LatsestProducts'
import EmailSignUp from '../components/EmailSignUp'
import Footer from '../components/Footer'

function Home({scrollState}) {
  return (
    <>
      <div className={`home-page ${scrollState ? 'active' : ''}`}>
        <Hero />
        <HoverClicks />
        <LatsestProducts />
        <EmailSignUp />
        <Footer />
      </div>
    </>
  )
}

export default Home
