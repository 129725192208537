import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import '../styles/productPage/productPage.css'
import 'swiper/css/navigation';

import { sweatpants } from '../data/items';
import { RandomItems } from '../components/RandomItems';

export const Sweatpants = ({cc, cartOpenFunc}) => {
   
    const [currentItem, setCurrentItem] = useState('')
    const [itemIndex, setItemIndex] = useState('')
    const [sizeIndex, setSizeIndex] = useState('')
    const [sizeState, setSizeState] = useState(false)

    const [sizeAdded, setSizeAdded] = useState(false)
  
    const [popUpVisibility, setPopUpVisibility] = useState(false)
    const [sizePopUpVisibility, setSizePoUpVisibility] = useState(false)
  
    useEffect(() => {  
      let currentT = JSON.parse(localStorage.getItem('/Sweatpant'));
      if (currentT) {
        setCurrentItem(currentT);
      }
    }, []);
  
    useEffect(() => {
      localStorage.setItem('/Sweatpant', JSON.stringify(currentItem))
      for (let i = 0; i < sweatpants.length; i++) {
        if (sweatpants[i].colorName == currentItem.colorName) {
          setItemIndex(i)
        }
      }
  
      for (let i = 0; i < sweatpants.length; i++) {
        if (sweatpants[i].colorName == currentItem.colorName) {
          setItemIndex(i)
        }
      }
    }, [currentItem])
  
    function colorSelect(color) {
      const newColor = sweatpants.filter((item) => item.color == color)
      setCurrentItem(newColor[0])
    }

    function setI(i) {
      const current = currentItem
      if (current.des == i.des) {
        window.location.reload();
      }
      setCurrentItem(i)
  
    }
  
  
    function addSize(size) {
      currentItem.size = `${size}`
      setSizeIndex(size)
      setSizeState(true)
      setSizeAdded(true)
    }
  
    function atc(item) {

      if(!sizeAdded) {
        sizepopupVis()
        return
      } else {
        if (sizeState === true) {
          popupVis()
        }
      }
      

      let currentCart = JSON.parse(localStorage.getItem('cart')) || []
      let newItemArray = []


      if (currentCart.length == 0 ) {
        newItemArray.push(item)
        let updatedCart = [...currentCart, newItemArray]
        localStorage.setItem('cart', JSON.stringify(updatedCart))

      } 

      let itemAdded = false

      if (currentCart.length > 0) {

        for (let cartArr of currentCart) {

          if (item.color == cartArr[0].color && item.size == cartArr[0].size) {
            let updatedCurrentCart = currentCart.filter(arr => arr !== cartArr)
            cartArr.push(item)
            let updatedCart = [...updatedCurrentCart, cartArr]
            localStorage.setItem('cart', JSON.stringify(updatedCart))
            itemAdded = true
          } 

        }
      }

      if (itemAdded == false && currentCart.length > 0) {
        newItemArray.push(item)
        let updatedCart = [...currentCart, newItemArray]
        localStorage.setItem('cart', JSON.stringify(updatedCart))

      }
  

      cc()
      if (currentCart < 1) {
        cartOpenFunc()
      }
    }
  
    function popupVis() {
      setPopUpVisibility(true)
      setTimeout(function() {
        setPopUpVisibility(false)
      }, 2000)
    }
  
    function sizepopupVis() {
      setSizePoUpVisibility(true)
      setTimeout(function() {
        setSizePoUpVisibility(false)
      }, 2000)
    }
  
  
    const sizes = ['S', 'M', 'L', 'XL']
  
    return (
      <>
        <div className='spacer'></div>
        <div className='product-page-flex'>
          <h1 className='product-name-mobile'>{currentItem.itemName}</h1>
          <div className='product-page-left'>
            <Swiper className="mySwiper2"
              spaceBetween={0}
              slidesPerView={1}
              pagination={{ clickable: true,}}
              modules={[Pagination, Navigation]}
              navigation={true}
            >
              <SwiperSlide className='swiper-slide2'><img src={currentItem.image} alt="" /></SwiperSlide>
              <SwiperSlide className='swiper-slide2'><img src={currentItem.image2} alt="" /></SwiperSlide>
            </Swiper>
          </div>
          <div className='product-page-right'>
            <h1 className='product-name'>{currentItem.itemName}</h1>
            <h1 className='product-price'>£{currentItem.itemPrice} </h1>
            <h2 className='product-description'>{currentItem.des}</h2>
            <h3 className='select-option-title'>Select colour</h3>
            <div className='color-selector-wrapper'>
              {sweatpants.map((item, index) => {
                return <ColorCircle id={item.id} col={item.color} clickFunction={() => colorSelect(item.color, index)} key={index} kup={index} ii={itemIndex}></ColorCircle>
              })}
            </div>
            <h3 className='select-option-title'>Select size</h3>
            <div className='sizes-selector-wrapper'>
                { sizes.map((size, index) => {
                    return <SizeRectangle letter={size} key={index} clickFunction={() => addSize(size)} si={sizeIndex}></SizeRectangle>
                })}
            </div>
            <button className='atc-btn' onClick={() => atc(currentItem)}>Add to cart</button>
            <ATCPopUp vis={popUpVisibility}></ATCPopUp>
            <SizePopUp tis={sizePopUpVisibility}></SizePopUp>
          </div>    
        </div>
        <RandomItems page={`/Sweatpant`} duoFunc={setI}/>     
      </>
    )
  }
  
  const ColorCircle = ({clickFunction, col , ii, kup}) => {
  
      const [colorTrue, setColorTrue] = useState(false)
    
      useEffect(() => {
        // Update colorTrue when kup is equal to ii
        setColorTrue(kup === ii);
        if ( kup == ii) {
          setColorTrue(true)
        } else {
          setColorTrue(false)
        }
      }, [kup, ii]);
    
    
      return (
        <div className={`color-selector-circle ${colorTrue ? 'active' : ''}`} onClick={clickFunction} style={{backgroundColor:col, }}></div>
      )
    }
    
    const SizeRectangle = ({clickFunction, ii, kup, letter, si}) => {
    
      const [colorTrue, setColorTrue] = useState(false)
    
      useEffect(() => {
        // Update colorTrue when kup is equal to ii
        setColorTrue(letter === si);
        if ( si == letter) {
          setColorTrue(true)
        } else {
          setColorTrue(false)
        }
      }, [si, letter]);
    
    
      return (
        <div className={`size-selector ${colorTrue ? 'active' : ''}`} onClick={clickFunction}>{letter}</div>
      )
    }
    
    const ATCPopUp = ({vis}) => {
    
      return (
        <div className={`ATCPop-up-container ${vis ? 'active' : ''}`}>
          <h6>Added to cart</h6>
        </div>
      )
    }
    
    const SizePopUp = ({tis}) => {
    
      return (
        <div className={`SizePop-up-container ${tis ? 'active' : ''}`}>
          <h6>Select size</h6>
        </div>
      )
    }

