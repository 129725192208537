import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";

import '../styles/Head.css'

import whiteCartImg from '../assets/cart-white.png'
import blackCartImg from '../assets/cart-black.png'

function Head({hCState, cc, cartShutFunc, cartEmptyState, finishState}) {

    const location = useLocation()

    const [headerCState, setHeaderCState] = useState(false)
  
    useEffect(() => {
  
      if (location.pathname != '/') {
         setHeaderCState(true)
         setHeaderState(true)
      }
  
      if (location.pathname == '/') {
        setHeaderCState(false)
     }
  
  
    }, [location])

    const [headerState, setHeaderState] = useState(false)
    const [dimState, setDimState] = useState(false)
    const [clothingState, setClothingState] = useState(false)
    const [accessoriesState, setAccessoriesState] = useState(false)
    const [cartLength, setCartLength] = useState(JSON.parse(localStorage.getItem('cart')))

    const headerOver = () => {
        if (location.pathname == '/') {
            if (headerState == true) {
                return
            } else {
                setHeaderState(!headerState)
            }
        }
    }

    const headerOut = () => {
        if (location.pathname == '/') {
            if (clothingState || accessoriesState == true) {
                return
            } else {
                setHeaderState(!headerState)
            }
        }
    }

    const clothingClicked = () => {
        setClothingState(!clothingState)
        setDimState(true)
        if (accessoriesState == true) {
            setAccessoriesState(false)
        }
        if (dimState == true && clothingState == true) {
            setDimState(false)
        }
    }

    const accessoriesClicked = () => {
        setAccessoriesState(!accessoriesState)
        setDimState(true)
        if (clothingState == true) {
            setClothingState(false)
        }
        if (dimState == true && accessoriesState == true) {
            setDimState(false)
        }
    }

    const dimClicked = () => {
        setDimState(!dimState)
        setClothingState(false)
        setAccessoriesState(false)
        setHeaderState(false)
    }

    useEffect(() => {
        if (hCState == false) {
            setHeaderState(true)
        }
        
    }, [])

  return (
    <>
        <header className={`header ${finishState ? 'active' : ''}`}>
            <section className={`desktop-header ${headerState ? 'active' : ''}`} onMouseEnter={headerOver} onMouseLeave={headerOut}>
                <div className='desktop-header-left'>
                    <NavSection headerCState={headerCState} headerState={headerState} dropdownState={clothingState} dropdownClick={clothingClicked} data={clothingData} stat={hCState}/>
                    <NavSection headerCState={headerCState} headerState={headerState} dropdownState={accessoriesState} dropdownClick={accessoriesClicked} data={accessoriesData} stat={hCState}/>
                </div>
                <div className='desktop-header-center'><Link to={'/'} ><h1 className={`header-logo-text ${headerState ? 'dark' : ''} ${headerCState ? 'darkC' : ''}`}>clothesdspace</h1></Link></div>
                <div className='desktop-header-right'>
                    <Link to={'/Cart'} onClick={cartShutFunc}><div className="cart-img-wrapper"><img src={headerState ? blackCartImg : whiteCartImg} alt="" /></div></Link>
                    <div className={`cart-count-num ${cc ? 'active' : ''}`}>{cc}</div>
                    <div className={`cart-empty ${cartEmptyState ? 'active' : ''}`}>Cart is empty</div>
                </div>
            </section>
            <div className={`dim ${dimState ? 'active' : ''}`} onClick={dimClicked}></div>
        </header>
    </>
  )
}

function NavSection({headerState, dropdownState, dropdownClick, data, stat, headerCState}) {

    const dataTitle = data.title
    const dataSubs = data.subs

    const titleRef = useRef()

    useEffect(() => {
        // Ensure titleRef.current is defined before manipulating style
        if (dropdownState) {
            titleRef.current.style.textDecoration = 'underline'
        } else {
            titleRef.current.style.textDecoration = 'none'
        } 
      }, [dropdownState]);


    return (
        <>
            <div className='nav-section'>
                <h3 ref={titleRef} className={`section-title ${headerState ? 'dark' : ''} ${headerCState ? 'darkC' : ''}`} onClick={dropdownClick}>{dataTitle} </h3>
                <div className={`dropdown-section ${dropdownState ? 'active' : ''}  `}>
                    {
                        dataSubs.map((item, idx) => {
                            return <SubSection key={idx} data={item} stat2={stat}/>
                        })
                    }
                </div>
            </div>
        </>
    )
}

function SubSection({data}) {

    const subTitle = data.subTitle
    const subs = data.subs

    const [noGo, setNoGo] = useState(false)

    function noGoClicked(e) {
        const target = e.target
        target.classList.add('active')
        setTimeout(function() {
          target.classList.remove('active');
        }, 500)
        
      }


    return (
        <>
            <div className='sub-section'>
                <h1 className='sub-title'>{subTitle}</h1>
                <div className='subs'>
                {
                    subs.map((item, idx) => {
                        return <h3 className={`sub ${noGo ? 'active' : ''}`} onClick={noGoClicked} key={idx}>{item}</h3>
                    })
                }
                </div>
            </div>
        </>
    )
}

const clothingData = {
    title: 'Clothing',
    subs: [
        {
            subTitle: 'Outerwear',
            subs: ['Jackets', 'Fleece']
        },
        {
            subTitle: 'Tops',
            subs: ['T-shirts', 'Shirts', 'Sweatshirts', 'Jumpers', 'Knits']
        },
        {
            subTitle: 'Bottoms',
            subs: ['Trousers', 'Chinos', 'Jeans', 'Shorts']
        },
        {
            subTitle: 'Footwear',
            subs: ['Trainers', 'Boots', 'Shoes']
        },
    ]
}

const accessoriesData = {
    title: 'Accessories',
    subs: [
        {
            subTitle: 'Hats',
            subs: ['Beanies', 'Caps']
        },
        {
            subTitle: 'Bags',
            subs: ['Backpacks', 'Duffel bags', 'Shoulder bags', 'Tote bags']
        },
        {
            subTitle: 'Else',
            subs: ['Belts', 'Scarfs', 'Wallets', 'Gloves']
        }
    ]
}

export default Head