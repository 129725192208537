import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";

import '../styles/Head.css'

import menuBlack from '../assets/icons/menu.png'
import exitBlack from '../assets/icons/close.png'

export default function MobileHeader({toggleScrollFunc, cc, cartShutFunc, cartEmptyState, finishState}) {

    const [headerCState, setHeaderCState] = useState(false)
    const [headerState, setHeaderState] = useState(false)

    const [mobileMenuState, setMobileMenuState] = useState(false)

    const cart = JSON.parse(localStorage.getItem('cart'))

    function toggleMenu() {
      if (mobileMenuState) {
        setMobileMenuState(false)
      } else {
        setMobileMenuState(true)
      }
      toggleScrollFunc()
    }

    function handleResize() {
      setMobileMenuState(false)
    }

    useEffect(() => {
      window.addEventListener('resize', handleResize)
    }, [])

    const location = useLocation()

    function toCart(e) {
      cartShutFunc(e)
      if (cart.length > 0) {
        setMobileMenuState(false)
        toggleScrollFunc()
      }

    }

  return (
    <>
        <header className={`mobile-header-container ${finishState ? '' : 'active'}`}>
          <section className={`mobile-header `}>
            <div className='mobile-header-left'>
            </div>
            <div className='mobile-header-center'><Link to={'/'} ><h1 className={`mobile-header-logo-text ${headerState ? 'dark' : ''} ${headerCState ? 'darkC' : ''}`}>clothesdspace</h1></Link></div>
            <div className='mobile-header-right'>
                <div className={`burger-container ${mobileMenuState ? '' : 'active'}`} onClick={toggleMenu}><img src={menuBlack} ></img></div>
                <div className={`exit-container ${mobileMenuState ? 'active' : ''}`} onClick={toggleMenu}><img src={exitBlack} alt="" /></div>
            </div>
          </section>
          <section className={`mobile-menu ${mobileMenuState ? 'active' : ''}`} >
                  <MenuSub data={clothingData} hr={mobileMenuState}/>
                  <MenuSub data={accessoriesData} hr={mobileMenuState}/>
                  <Link to={'/Cart'}  ><div>
                    <h1 className='mobile-cart-text' onClick={toCart}>Cart</h1>
                    <div className={`cart-count-num-mob ${cc ? 'active' : ''}`}>{cc}</div>
                    <div className={`cart-empty-mob ${cartEmptyState ? 'active' : ''}`}>Cart is empty</div>
                  </div> </Link>
          </section>
        </header>
    </>
  )
}

function MenuSub({data, hr}) {

  const subs = data.subs

  const [sectionToggle, setSectionToggle] = useState(false)

  function togglesSection() {
    if (sectionToggle) {
      setSectionToggle(false)
    } else {
      setSectionToggle(true)
    }
  }

  useEffect(() => {
    if (!hr) {
      setSectionToggle(false)
    }
  }, [hr])
  

  return (
    <>
      <div className='mobile-menu-section'>
        <h1 className={`mobile-section-title ${sectionToggle ? 'active' : ''}`} onClick={togglesSection}>{data.title}</h1>
        <div className={`mobile-sections ${sectionToggle ? 'active' : ''}`}>
          {
            subs.map((sub, idx) => {
              return <SectionSubMenu data={sub} st={sectionToggle} hrplus={hr}/>
            })
          }
        </div>
      </div>
    </>
  )
}

function SectionSubMenu({data, st, hrplus}) {

  const [sectionSubToggle, setSectionSubToggle] = useState(false)

  function toggleSubSection() {
    if (sectionSubToggle) {
      setSectionSubToggle(false)
    } else {
      setSectionSubToggle(true)
    }
  }

  useEffect(() => {
    if (!st) {
      setSectionSubToggle(false)
    }
  }, [st])

  useEffect(() => {
    if (!hrplus) {
      setSectionSubToggle(false)
    }
  }, [hrplus])

  const [noGo, setNoGo] = useState(false)

  function noGoClicked(e) {
    const target = e.target
    target.classList.add('active')
    setTimeout(function() {
      target.classList.remove('active');
    }, 500)
    
  }

  return (
    <>
        <div className='mobile-section-menu'>
            <h1 className={`mobile-section-menu-title ${sectionSubToggle ? 'active' : ''}`} onClick={toggleSubSection}>{data.subTitle}</h1>
            <div className={`mobile-section-menu-links ${sectionSubToggle ? 'active' : ''}`}>
              {
                data.subs.map((sub, idx) => {
                  return <h2 className={`mobile-section-link ${noGo ? 'active' : ''}`} onClick={noGoClicked}>{sub}</h2>
                })
              }
            </div>
        </div>
    </>
  )
}


const clothingData = {
  title: 'Clothing',
  subs: [
      {
          subTitle: 'Outerwear',
          subs: ['Jackets', 'Fleece']
      },
      {
          subTitle: 'Tops',
          subs: ['T-shirts', 'Shirts', 'Sweatshirts', 'Jumpers', 'Knits']
      },
      {
          subTitle: 'Bottoms',
          subs: ['Trousers', 'Chinos', 'Jeans', 'Shorts']
      },
      {
          subTitle: 'Footwear',
          subs: ['Trainers', 'Boots', 'Shoes']
      },
  ]
}

const accessoriesData = {
  title: 'Accessories',
  subs: [
      {
          subTitle: 'Hats',
          subs: ['Beanies', 'Caps']
      },
      {
          subTitle: 'Bags',
          subs: ['Backpacks', 'Duffel bags', 'Shoulder bags', 'Tote bags']
      },
      {
          subTitle: 'Else',
          subs: ['Belts', 'Scarfs', 'Wallets', 'Gloves']
      }
  ]
}